import "./styles.css";
import usePartySocket from "partysocket/react";
import { createRoot } from "react-dom/client";
import React, { useState } from "react";

import { HOST_ACTIONS_BY_STATE } from "./transitions";

function App() {
  const [session, setSession] = useState({})
  const [messages, setMessages] = useState([])
  const [message, setMessage] = useState('')
  const [connectionType, setConnectionType] = useState('player')

  const patchStore = (data) => {
   setSession({
    ...session,
    ...data,
   })
  }

  const ws = usePartySocket({
    // host: "http://localhost:1999/",
    // host: "https://partykit-dekalb-games.ryan-hogard-paradowski.partykit.dev/",
    room: "default",
    onOpen() {
      console.log("connected");
    },
    onMessage(event) {
      try {
        const data = JSON.parse(event.data);
        switch(data.type) {
          case 'message':
            setMessages([...messages, data]);
            break;
          case 'session':
            patchStore(data.session);
            break;
          default:
            console.info('unhandled', data)
        }
      } catch (error) {
        console.error('Invalid message:', event.data);
      }
    },
    onClose() {
      console.log("closed");
    },
    onError(event) {
      console.log("error");
      console.error(event);
    }
  });

  const sendMessage = () => {
    ws.send(JSON.stringify({ type: "message", message: message }));
    setMessage('');
  }

  const sendConnectionType = (connectionType) => {
    setConnectionType(connectionType)
    ws.send(JSON.stringify({ type: "setConnectionType", connectionType }));
  }

  const sendTransition = (transition) => {
    ws.send(JSON.stringify({ type: "setTransition", transition }));
  }

  return (<div>
    <h1>PartyKit Dekalb Games</h1>
    <h3>Connection Type: {connectionType}</h3>
    <h3>Game Stage: {session?.state?.stage}</h3>
    <hr/>
    <button onClick={() => sendConnectionType('host')}>Set connection type to host</button>
    <br/>
    <button onClick={() => sendConnectionType('display')}>Set connection type to display</button>
    <br/>
    <button onClick={() => sendConnectionType('player')}>Set connection type to player</button>
    <hr/>
    {connectionType === 'host' && (<div>
      {Object.entries(HOST_ACTIONS_BY_STATE).map(([stage, { forward, back }]) => (<div key={stage}>
        <h4>{stage}</h4>
        {forward && (<div>
          <button
            disabled={session.state.stage !== stage}
            onClick={() => session.state.stage == stage && sendTransition(forward)}
          >{forward}</button>
        </div>)}
        {back && (<div>
          <button
            disabled={session.state.stage !== stage}
            onClick={() => session.state.stage == stage && sendTransition(back)}
          >{back}</button>
        </div>)}
        <hr/>
      </div>))}
    </div>)}
    <h3>Messages:</h3>
    <button onClick={
      () => ws.send(JSON.stringify({ type: "tapCount", teamId: 'red', count: 1 }))
    }>tapCount</button>
    <button onClick={
      () => ws.send(JSON.stringify({ type: "joinCount", teamId: 'red', count: 1 }))
    }>joinCount</button>
    <hr/>
    <pre>{JSON.stringify(session, null, 2)}</pre>
  </div>);
}

createRoot(document.getElementById("app")).render(<App />);
